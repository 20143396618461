import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "325",
  height: "639",
  viewBox: "0 0 325 639",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      d: "M362.845 0.0874838C462.184 2.95372 530.233 83.5079 598.085 149.742C662.586 212.705 738.523 278.147 735.936 364.282C733.385 449.175 658.016 514.06 585.539 567.381C521.875 614.218 444.57 641.869 362.845 638.763C284.499 635.785 218.254 596.864 157.97 551.13C89.2196 498.973 9.97511 445.432 1.04067 364.282C-8.45115 278.069 48.4451 199.935 112.235 135.723C180.037 67.4732 261.913 -2.82471 362.845 0.0874838Z",
      fill: "#FFFAE8"
    }, null, -1)
  ])))
}
export default { render: render }